import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

function UsersSection({ title, subtitle }) {
  return (
    <div className="section">
      <div className="container">
        <h2>{title || "Find your niche"}</h2>
        <div className="subtitle">
          {subtitle || "Hyperlink helps business owners get real time insights about their business."}
        </div>
        <div className="word-cloud">
          <a target="_blank" href="/instagram/">instagram influencers</a>
          <a target="_blank" href="/etsy/">etsy sellers</a>
          <a target="_blank" href="/kickstarter/">kickstarter campaigns</a>
          <a target="_blank" href="/youtube/">youtube creators</a>
          <a target="_blank" href="/app-developers/">app developers</a>
          <a target="_blank" href="/soundcloud/">soundcloud artists</a>
          <a target="_blank" href="/eventbrite/">eventbrite promoters</a>
          <a target="_blank" href="/shopify/">shopify stores</a>
          <a target="_blank" href="/airbnb/">airbnb hosts</a>
          <a target="_blank" href="/real-estate-agents/">real estate agents</a>
          <a target="_blank" href="/medium/">medium authors</a>
          <a target="_blank" href="/journalists/">journalists</a>
          <a target="_blank" href="/authors/">authors</a>
          <a target="_blank" href="/amazon/">amazon merchants</a>
          <a target="_blank" href="/maps/">google maps users</a>
          <a target="_blank" href="/facebook/">facebook pages</a>
          <a target="_blank" href="/designers">designers</a>
          <a target="_blank" href="/job-seekers/">job seekers</a>
          <a target="_blank" href="/recruiters/">recruiters</a>
          <a target="_blank" href="/salespeople/">salespeople</a>
          <a target="_blank" href="/photographers/">photographers</a>
          <a target="_blank" href="/avon/">avon reps</a>
          <a target="_blank" href="/mary-kay/">mary kay consultants</a>
          <a target="_blank" href="/nonprofits/">nonprofits</a>
          <a target="_blank" href="/github/">github repos</a>
          <a target="_blank" href="/twitch/">twitch streamers</a>
          <a target="_blank" href="/venmo/">venmo users</a>
          <a target="_blank" href="/entrepreneurs/">entrepreneurs</a>
          <a target="_blank" href="/e-commerce/">e-commerce retailers</a>
          <a target="_blank" href="/zapier/">zapier users</a>
          <a target="_blank" href="/podcast/">podcasters</a>
          <a target="_blank" href="/product-managers/">product managers</a>
          <a target="_blank" href="/teachers/">teachers</a>
          <a target="_blank" href="/law/">law firms</a>
          <a target="_blank" href="/marketers/">marketers</a>
          <a target="_blank" href="/pinterest/">pinterest users</a>
          <a target="_blank" href="/retail/">retail stores</a>
          <a target="_blank" href="/restaurants/">restaurants</a>
          <a target="_blank" href="/tiktok/">tiktok users</a>
          <a target="_blank" href="/paypal/">paypal users</a>
          <a target="_blank" href="/wordpress/">wordpress sites</a>
        </div>
      </div>
    </div>
  );
}

export default UsersSection;