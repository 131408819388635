import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

function DetailsSection({ title, subtitle }) {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "assets/details.png" }) {
      childImageSharp {
        fluid(maxWidth: 520) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <div className="section">
      <div className="container">
        <div className="section-image">
          <Img style={{ margin: "0 -3% 8px -3%" }} fluid={data.file.childImageSharp.fluid} />
        </div>
        <h2>{title || "Dive into the details"}</h2>
        <div className="subtitle">{subtitle || "Location, device, and referral information for each and every visitor."}
        </div>
      </div>
    </div>
  );
}

export default DetailsSection;