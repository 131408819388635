import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

function EasySection({ title, subtitle }) {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "assets/create.png" }) {
      childImageSharp {
        fluid(maxWidth: 520) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (<div className="section section-screenshot">
    <div className="container">
      <h2>{title || <span>Lightning fast &<br />super simple</span>}</h2>
      <div className="subtitle">{subtitle || <span>Create Hyperlinks with one click — no setup or code required.</span>}</div>
      <div className="section-image screenshot">
        <Img style={{ marginTop: "40px" }} fluid={data.file.childImageSharp.fluid} />
      </div>
    </div>
  </div >
  );
}

export default EasySection;