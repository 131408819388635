import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

function DomainSection({ title, subtitle }) {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "assets/message.png" }) {
      childImageSharp {
        fluid(maxWidth: 520) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <div className="section">
      <div className="container">
        <div className="section-image custom-domain">
          <a target="_blank" href="/subscription/">
            <Img style={{}} fluid={data.file.childImageSharp.fluid} />
          </a>
        </div>
        <h2>{title || <span>Inspire trust with Branded links</span>}</h2>
        <div className="subtitle">{subtitle || "Branded Hyperlinks have 34% higher click-through rates than unbranded links."}
        </div>
        <div className="section-button">
          <a target="_blank" className="learn-more" href="/subscription/">Get Custom Domain</a>
        </div>
      </div>
    </div>
  );
}

export default DomainSection;