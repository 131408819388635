import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Push from "../components/push"
import EasySection from "../components/easy_section"
import ChromeSection from "../components/chrome_section"
import DomainSection from "../components/domain_section"
import UsersSection from "../components/users_section"
import DashboardSection from "../components/dashboard_section"
import DetailsSection from "../components/details_section"
import NotificationsSection from "../components/notifications_section"
import ShareSection from "../components/share_section"
import CallToActionSection from "../components/call_to_action_section"
import DeliverySection from "../components/delivery_section"

class Features extends React.Component {
  scrollDown() {
    var sections = document.getElementsByClassName("section");
    if (!sections || sections.length < 2) {
      return;
    }
    const y = sections[1].getBoundingClientRect().top + window.pageYOffset - 64;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Features" slug="/features/" />
        <div className="section above-the-fold">
          <div className="container">
            <Push linkName="Twitter Link" />
            <div className="hero">
              <h2>Real-time notifications</h2>
              <div className="subtitle">Get push notifications when your links are clicked.</div>
              <div onClick={this.scrollDown.bind(this)} className="material-icons down-arrow">keyboard_arrow_down</div>
            </div>
          </div>
        </div>
        <NotificationsSection title="Frequency settings" />
        <DomainSection title="Custom domain names" />
        <ShareSection />
        <DetailsSection title="Per-click details" />
        <DashboardSection title="Live dashboards" />
        <DeliverySection />
        <ChromeSection />
        <UsersSection title="Built for your niche" subtitle="Hyperlink is built for creatives, entrepreneurs, salespeople & beyond." />
        <EasySection title="Mobile apps" />
        <CallToActionSection />
      </Layout>
    )
  }
}

export default Features

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
