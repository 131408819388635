import React from "react"

function Push({ linkName }) {
  if (!linkName) linkName = "link"
  return (
    <div className="notification-container">
      <div className="notification-background" style={{ animation: "appear-1 8s ease-in-out both infinite" }}>
        <div className="notification">
          <div className="notification-header">
            <div className="notification-icon">
              HYPER
        </div>
            <div className="notification-heading">
              HYPERLINK
        </div>
            <div className="notification-timing">
              now
        </div>
          </div>
          <div className="notification-title">
            Someone in San Francisco clicked on your link!
      </div>
          <div className="notification-body">
            They clicked on your {linkName} using an iPhone.
      </div>
        </div>
      </div>
      <div className="notification-background" style={{ animation: "appear-2 8s ease-in-out both infinite" }}>
        <div className="notification">
          <div className="notification-header">
            <div className="notification-icon">
              HYPER
        </div>
            <div className="notification-heading">
              HYPERLINK
        </div>
            <div className="notification-timing">
              now
        </div>
          </div>
          <div className="notification-title">
            Someone in New York clicked on your link!
      </div>
          <div className="notification-body">
            They clicked on your {linkName} using an iPhone.
      </div>
        </div>
      </div>
      <div className="notification-background" style={{ animation: "appear-3 8s ease-in-out both infinite" }}>
        <div className="notification">
          <div className="notification-header">
            <div className="notification-icon">
              HYPER
        </div>
            <div className="notification-heading">
              HYPERLINK
        </div>
            <div className="notification-timing">
              now
        </div>
          </div>
          <div className="notification-title">
            Someone in Los Angeles clicked on your link!
      </div>
          <div className="notification-body">
            They clicked on your {linkName} using an PC.
      </div>
        </div>
      </div>
      <div className="notification-background" style={{ animation: "appear-5 8s ease-in-out both infinite" }}>
        <div className="notification">
          <div className="notification-header">
            <div className="notification-icon">
              HYPER
        </div>
            <div className="notification-heading">
              HYPERLINK
        </div>
            <div className="notification-timing">
              now
        </div>
          </div>
          <div className="notification-title">
            Someone in Paris clicked on your link!
      </div>
          <div className="notification-body">
            They clicked on your {linkName} using a Pixel.
      </div>
        </div>
      </div>
      <div className="notification-background" style={{ animation: "appear-6 8s ease-in-out both infinite" }}>
        <div className="notification">
          <div className="notification-header">
            <div className="notification-icon">
              HYPER
        </div>
            <div className="notification-heading">
              HYPERLINK
        </div>
            <div className="notification-timing">
              now
        </div>
          </div>
          <div className="notification-title">
            Someone in London clicked on your link!
      </div>
          <div className="notification-body">
            They clicked on your {linkName} using a Mac.
      </div>
        </div>
      </div>
      <div className="notification-background" style={{ animation: "appear-8 8s ease-in-out both infinite" }}>
        <div className="notification">
          <div className="notification-header">
            <div className="notification-icon">
              HYPER
        </div>
            <div className="notification-heading">
              HYPERLINK
        </div>
            <div className="notification-timing">
              now
        </div>
          </div>
          <div className="notification-title">
            Someone in San Francisco clicked on your link!
      </div>
          <div className="notification-body">
            They clicked on your {linkName} using an iPhone.
      </div>
        </div>
      </div>
    </div >
  );
}

export default Push;