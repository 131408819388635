import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

function DashboardSection({ title, subtitle }) {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "assets/dashboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 520) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <div className="section">
      <div className="container">
        <div className="section-image">
          <Img style={{ margin: "0 -3% 8px -3%" }} fluid={data.file.childImageSharp.fluid} />
        </div>
        <h2>{title || "Real-time analytics"}</h2>
        <div className="subtitle">{subtitle || "Get a live look at the visitors clicking on your links. No more waiting for yesterday's data."}
        </div>
      </div>
    </div>
  );
}

export default DashboardSection;