import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

function ChromeSection({ title, subtitle }) {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "assets/chrome.png" }) {
      childImageSharp {
        fluid(maxWidth: 520) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <div className="section">
      <div className="container">
        <div className="section-image chrome">
          <a target="_blank"
            href="https://chrome.google.com/webstore/detail/hyperlink/dlgncnofjdagniahkcimljhineapppbi">
            <Img style={{ margin: "0 -3% 8px -3%" }} fluid={data.file.childImageSharp.fluid} />
          </a>
        </div>
        <h2>{title || "Chrome Extension"}</h2>
        <div className="subtitle">
          {subtitle || "Create Hyperlinks from the comfort of your favorite browser."}
        </div>
        <div className="section-button">
          <a className="learn-more" target="_blank"
            href="https://chrome.google.com/webstore/detail/hyperlink/dlgncnofjdagniahkcimljhineapppbi">Get Chrome
      Extension</a>
        </div>
      </div>
    </div>
  );
}

export default ChromeSection;